
@import "./Analysis";
@include inputError;
.first-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 55px;
  display: flex;
  @include validateForm;
}
.common-flex {
  @include InputFlex;
}
.btn-flex {
  @include btnFlex;
}
